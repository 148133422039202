import React, { useState } from 'react';
import firebaseApi from '../utils/firebase';

import {
    MDBContainer,
    MDBCol,
    MDBRow,
    MDBBtn,
    MDBIcon,
    MDBInput,
    MDBCheckbox
} from 'mdb-react-ui-kit';
import {
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
} from 'mdb-react-ui-kit';
import {
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
    MDBTabsPane,
} from 'mdb-react-ui-kit';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardText } from 'mdb-react-ui-kit';
import { useCookies } from 'react-cookie';

const Authentication = (props) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [phone, setPhone] = useState('');
    const [lastName, setLastName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [justifyActive, setJustifyActive] = useState('tab1');
    const [showTerms, setShowTerms] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(['u_u']);

    const handleJustifyClick = (value) => {
        if (value === justifyActive) {
            return;
        }

        setJustifyActive(value);
    };

    const handleGoogleSignIn = async () => {
        try {
            firebaseApi.handleGoogleSignIn().then(res => {
                console.log("handleGoogleSignIn: ", res);
                if (res.status) {
                    firebaseApi.getUserData(res.result.user.uid).then((userD) => {
                        if (userD.status) {
                            setCookie("u_u", JSON.stringify(userD.user));
                            props.setUser(userD.user);
                        } else {
                            var user = {
                                uid: res.result.user.uid,
                                provider: res.credential.providerId,
                                signInMethod: res.credential.signInMethod,
                                idToken: res.credential.idToken,
                                accessToken: res.credential.accessToken,
                                displayName: res.result.user.displayName,
                                firstName: "",
                                lastName: "",
                                email: res.result.user.email,
                                emailVerified: res.result.user.emailVerified,
                                phoneNumber: res.result.user.phoneNumber,
                                photoURL: res.result.user.photoURL,
                                userAccessToken: res.result.user.accessToken,
                                address: "",
                                facebook: "",
                                whatsapp: "",
                                twitter: "",
                                github: "",
                                portfolio: "",
                            };
                            console.log("user: ", user)
                            firebaseApi.updateUserData(user).then((uRes) => {
                                console.log("uRes: ", uRes)
                                if (!uRes.failed) {
                                    setCookie("u_u", JSON.stringify(user));
                                    props.setUser(user);
                                }
                            });
                        }
                    });
                }
            })
            // Google sign-in successful
        } catch (error) {
            console.error('Error signing in with Google:', error);
        }
    };

    const handleGithubSignIn = async () => {
        try {
            firebaseApi.handleGithubSignIn().then(res => {
                console.log("handleGithubSignIn: ", res);
                if (res.status) {
                    firebaseApi.getUserData(res.result.user.uid).then((userD) => {
                        if (userD.status) {
                            setCookie("u_u", JSON.stringify(userD.user));
                            props.setUser(userD.user);
                        } else {
                            var user = {
                                uid: res.result.user.uid,
                                provider: res.credential.providerId,
                                signInMethod: res.credential.signInMethod,
                                idToken: "",
                                accessToken: res.credential.accessToken,
                                displayName: res.result.user.displayName,
                                firstName: "",
                                lastName: "",
                                email: res.result.user.email,
                                emailVerified: res.result.user.emailVerified,
                                phoneNumber: res.result.user.phoneNumber,
                                photoURL: res.result.user.photoURL,
                                userAccessToken: res.result.user.accessToken,
                                address: "",
                                facebook: "",
                                whatsapp: "",
                                twitter: "",
                                github: "",
                                portfolio: "",
                            }
                            firebaseApi.updateUserData(user).then((uRes) => {
                                if (!uRes.failed) {
                                    setCookie("u_u", JSON.stringify(user));
                                    props.setUser(user);
                                }
                            });
                        }
                    });
                }
            })
            // Google sign-in successful
        } catch (error) {
            console.error('Error signing in with Google:', error);
        }
    };

    const handleEmailSignIn = async () => {
        try {
            firebaseApi.handleEmailSignIn(email, password).then(res => {
                console.log("handleEmailSignIn: ", res);
                if (res.status) {
                    firebaseApi.getUserData(res.result.user.uid).then((userD) => {
                        if (userD.status) {
                            setCookie("u_u", JSON.stringify(userD.user));
                            props.setUser(userD.user);
                        } else {
                            var user = {
                                uid: res.userCredential.user.uid,
                                provider: "email",
                                signInMethod: "email",
                                idToken: "",
                                accessToken: res.userCredential.user.accessToken,
                                displayName: firstName + " " + lastName,
                                firstName: firstName,
                                lastName: lastName,
                                email: email,
                                emailVerified: false,
                                phoneNumber: phone,
                                photoURL: "",
                                userAccessToken: res.userCredential.user.accessToken,
                                address: "",
                                facebook: "",
                                whatsapp: "",
                                twitter: "",
                                github: "",
                                portfolio: "",
                            }
                            firebaseApi.updateUserData(user).then((uRes) => {
                                console.log("uRes: ", uRes)
                                if (!uRes.failed) {
                                    setCookie("u_u", JSON.stringify(user));
                                    props.setUser(user);
                                }
                            });
                        }
                    });
                }
            });
        } catch (error) {
            console.error('Error signing in with email/password:', error);
        }
    };

    const handleEmailSignUp = async () => {
        try {
            firebaseApi.handleEmailSignUp(email, password).then(res => {
                console.log("handleEmailSignUp: ", res);
            });
        } catch (error) {
            console.error('Error signing up with email/password:', error);
        }
    };

    const handleSignOut = async () => {
        try {
            firebaseApi.handleSignOut(email, password).then(res => {
                console.log("handleSignOut: ", res);
            });
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    return (
        <MDBModal staticBackdrop open={props.showAuth} tabIndex='-1'>
            <MDBModalDialog size="lg">
                <MDBModalContent>
                    <MDBModalHeader>
                        <MDBModalTitle>Authentication</MDBModalTitle>
                        <MDBBtn className='btn-close' color='none' onClick={props.toggleOpen}></MDBBtn>
                    </MDBModalHeader>
                    <MDBModalBody>

                        {showTerms ? (
                            <TermsAndConditions setShowTerms={setShowTerms} />
                        ) : (
                            <MDBContainer fluid className="p-3 my-5">
                                <MDBTabs pills justify className='mb-3 d-flex flex-row justify-content-between'>
                                    <MDBTabsItem>
                                        <MDBTabsLink onClick={() => handleJustifyClick('tab1')} active={justifyActive === 'tab1'}>
                                            Login
                                        </MDBTabsLink>
                                    </MDBTabsItem>
                                    <MDBTabsItem>
                                        <MDBTabsLink onClick={() => handleJustifyClick('tab2')} active={justifyActive === 'tab2'}>
                                            Register
                                        </MDBTabsLink>
                                    </MDBTabsItem>
                                </MDBTabs>
                                <MDBTabsContent>

                                    <MDBTabsPane open={justifyActive === 'tab1'}>
                                        <MDBRow>
                                            <MDBCol col='10' md='6'>
                                                <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.svg" className="img-fluid" alt="Phone image" />
                                            </MDBCol>

                                            <MDBCol col='4' md='6'>
                                                <MDBInput wrapperClass='mb-4' label='Email address' id='formControlLg' type='email' size="md"
                                                    value={email} onChange={(e) => setEmail(e.target.value)} />
                                                <MDBInput wrapperClass='mb-4' label='Password' id='formControlLg' type='password' size="md" value={password}
                                                    onChange={(e) => setPassword(e.target.value)} />

                                                <div className="d-flex justify-content-between mx-4 mb-4">
                                                    {/* <MDBCheckbox name='flexCheck' value='' id='flexCheckDefault' label='Remember me' /> */}
                                                    <a href="!#">Forgot password?</a>
                                                </div>

                                                <MDBBtn className="mb-4 w-100" size="md" style={{ backgroundColor: "#283a5a" }} onClick={handleEmailSignIn}>Sign in</MDBBtn>


                                                <div className="divider d-flex justify-content-center my-4">
                                                    <p className="text-center fw-bold mx-3 mb-0">- OR -</p>
                                                </div>

                                                <MDBBtn className="mb-4 w-100" size="md" style={{ backgroundColor: '#dd4b39' }} onClick={handleGoogleSignIn}>
                                                    <MDBIcon fab icon="google" className="mx-2" />
                                                    Continue with Google
                                                </MDBBtn>

                                                <MDBBtn className="mb-4 w-100" size="md" style={{ backgroundColor: '#000000' }} onClick={handleGithubSignIn}>
                                                    <MDBIcon fab icon="github" className="mx-2" />
                                                    Continue with GitHub
                                                </MDBBtn>
                                            </MDBCol>

                                        </MDBRow>
                                    </MDBTabsPane>
                                    <MDBTabsPane open={justifyActive === 'tab2'}>
                                        <MDBRow>
                                            <MDBCol col='10' md='6'>
                                                <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.svg" className="img-fluid" alt="Phone image" />
                                            </MDBCol>


                                            <MDBCol col='4' md='6'>
                                                <MDBInput wrapperClass='mb-4' label='FirstName' id='FirstName' type='text' value={firstName} onChange={(e) => setFirstName(e.target.value)} />

                                                <MDBInput wrapperClass='mb-4' label='LastName' id='LastName' type='text' value={lastName} onChange={(e) => setLastName(e.target.value)} />

                                                <MDBInput wrapperClass='mb-4' label='Phone' id='Phone' type='tel' value={phone} onChange={(e) => setPhone(e.target.value)} />

                                                <MDBInput wrapperClass='mb-4' label='Email' id='Email' type='email' value={email} onChange={(e) => setEmail(e.target.value)} />

                                                <MDBInput wrapperClass='mb-4' label='Password' id='Password' type='password' value={password}
                                                    onChange={(e) => setPassword(e.target.value)} />

                                                <div className='d-flex justify-content-center mb-4'>
                                                    <MDBCheckbox name='flexCheck' id='flexCheckDefault' label='I have read and agree to the terms' />
                                                </div>
                                                <div className='d-flex justify-content-center mb-4'>
                                                    <div onClick={() => setShowTerms(!showTerms)}>Show Terms and Conditions</div>
                                                </div>

                                                <MDBBtn className="mb-4 w-100" onClick={handleEmailSignUp}>Sign up</MDBBtn>


                                                <div className="divider d-flex justify-content-center my-4">
                                                    <p className="text-center fw-bold mx-3 mb-0">- OR -</p>
                                                </div>

                                                <MDBBtn className="mb-4 w-100" size="md" style={{ backgroundColor: '#dd4b39' }} onClick={handleGoogleSignIn}>
                                                    <MDBIcon fab icon="google" className="mx-2" />
                                                    Continue with Google
                                                </MDBBtn>

                                                <MDBBtn className="mb-4 w-100" size="md" style={{ backgroundColor: '#000000' }} onClick={handleGithubSignIn}>
                                                    <MDBIcon fab icon="github" className="mx-2" />
                                                    Continue with GitHub
                                                </MDBBtn>
                                            </MDBCol>

                                        </MDBRow>
                                    </MDBTabsPane>
                                </MDBTabsContent>

                            </MDBContainer>)}
                    </MDBModalBody>

                    {/* <MDBModalFooter>
                        <MDBBtn color='secondary' onClick={props.toggleOpen}>
                            Close
                        </MDBBtn>
                        <MDBBtn>Save changes</MDBBtn>
                    </MDBModalFooter> */}
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    );
};

export default Authentication;


const TermsAndConditions = (props) => {
    return (
        <MDBContainer className="my-5">
            <MDBCard>
                <MDBCardBody>
                    <MDBCardTitle className="d-flex flex-row justify-content-between mb-4">
                        <div>Terms and Conditions</div>
                        <MDBBtn size='sm' onClick={() => props.setShowTerms(false)}>Close</MDBBtn>
                    </MDBCardTitle>
                    <MDBCardText>
                        These terms and conditions ("Terms") govern your use of Unitech Community ("the Website") and any services offered on or through the Website.
                        By using the Website, you agree to these Terms. If you do not agree with any part of these Terms, you may not use the Website.
                        <br /><br />
                        <strong>User Information and Content:</strong>
                        The Website is intended for IT professionals, developers, and individuals interested in [specify your website's purpose]. By using the Website and providing information, codes, solutions, or innovations ("User Content"), you grant us the non-exclusive, transferable, sub-licensable, royalty-free, worldwide license to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display the User Content in any form, media, or technology, whether now known or hereafter developed. We reserve the right to use the information provided by users for purposes including but not limited to improving our services, conducting research, and enhancing user experience.
                        <br /><br />
                        <strong>Intellectual Property Rights:</strong>
                        All codes, solutions, innovations, or any other materials shared by users on the Website ("User Contributions") shall become the property of Unitech Community. By submitting User Contributions, you grant us a perpetual, irrevocable, worldwide, royalty-free, and non-exclusive license to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display the User Contributions in any form, media, or technology.
                        <br /><br />
                        <strong>Disclaimer:</strong>
                        We do not guarantee the accuracy, completeness, or usefulness of any information or User Contributions provided on the Website. Users are responsible for verifying and validating the information or codes obtained through the Website.
                        <br /><br />
                        <strong>Modification of Terms:</strong>
                        We reserve the right to modify, update, or change these Terms at any time without prior notice. It is your responsibility to review these Terms periodically for changes. Your continued use of the Website after any modifications to the Terms constitutes acceptance of those changes.
                        <br /><br />
                        <strong>Governing Law:</strong>
                        These Terms shall be governed by and construed in accordance with the laws of [Your Country/State], without regard to its conflict of law principles.
                        <br /><br />
                        <strong>Contact Information:</strong>
                        If you have any questions about these Terms, please contact us at [Your Contact Email].
                        <MDBBtn size='sm' onClick={() => props.setShowTerms(false)}>Close</MDBBtn>
                    </MDBCardText>
                </MDBCardBody>
            </MDBCard>
        </MDBContainer>
    );
};

