import React from 'react';
import {
    MDBCol,
    MDBContainer,
    MDBRow,
    MDBCard,
    MDBCardText,
    MDBCardBody,
    MDBCardImage,
    MDBBtn,
    MDBBreadcrumb,
    MDBBreadcrumbItem,
    MDBProgress,
    MDBProgressBar,
    MDBIcon,
    MDBListGroup,
    MDBListGroupItem,
    MDBCardFooter,
    MDBInput,
    MDBTextArea,
    MDBCheckbox,

    MDBPagination,
    MDBPaginationItem,
    MDBPaginationLink,
    MDBRipple,
} from 'mdb-react-ui-kit';

import {
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
} from 'mdb-react-ui-kit';
import { MDBSpinner } from 'mdb-react-ui-kit';
import { MDBAccordion, MDBAccordionItem } from 'mdb-react-ui-kit';


import { Link, Navigate } from "react-router-dom";
import { async } from '@firebase/util';
import firebaseApi from '../utils/firebase';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

export default function ProfilePage(props) {

    const [articles, setArticles] = React.useState([]);
    const [projects, setProjects] = React.useState([]);
    const [challenges, setChallenges] = React.useState([]);
    const [showArticleEdit, setshowArticleEdit] = React.useState(false);
    const [showProjectEdit, setShowProjectEdit] = React.useState(false);
    const [showChallengeEdit, setShowChallengeEdit] = React.useState(false);
    const [showDetailsUpdate, setShowDetailsUpdate] = React.useState(false);
    const [article, setArticle] = React.useState();

    React.useEffect(() => {
        if (props.user) {
            getUserMisc();
        }
    }, [props.user]);


    const toggleOpen = () => setShowDetailsUpdate(!showDetailsUpdate);
    const toggleArticleOpen = () => setshowArticleEdit(!showArticleEdit);
    const toggleProjectOpen = () => setShowProjectEdit(!showProjectEdit);
    const toggleChallengeOpen = () => setShowChallengeEdit(!showChallengeEdit);

    const getUserMisc = async () => {
        firebaseApi.getDataWhere(props.user?.uid, "user-articles").then((arRes) => {
            console.log("arRes: ", arRes);
            setArticles(arRes);
        });
        firebaseApi.getDataWhere(props.user?.uid, "user-projects").then((prRes) => {
            console.log("prRes: ", prRes);
            setProjects(prRes);
        });

        firebaseApi.getAllDataFrom("user-challenges").then((chRes) => {
            console.log("chRes: ", chRes);
            setChallenges(chRes);
        });
    }

    const scrollToChallenge = () => {
        const element = document.getElementById("challenges");
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    return (
        <section style={{ backgroundColor: '#37517e' }}>
            {!props.user && <Navigate to="/" />}
            <MDBContainer className="py-5">
                <MDBRow>
                    <MDBCol className="bg-light rounded-3 pt-3 pb-1 mb-4 d-flex flex-row justify-content-between">
                        <MDBBreadcrumb>
                            <MDBBreadcrumbItem>
                                <Link to='/'>Home</Link>
                            </MDBBreadcrumbItem>
                            {/* <MDBBreadcrumbItem>
                                <a href="#">User</a>
                            </MDBBreadcrumbItem> */}
                            <MDBBreadcrumbItem active>User Profile</MDBBreadcrumbItem>
                        </MDBBreadcrumb>
                        <div>
                            {/* #dbad42 */}
                            {props.user?.userType == 2 ? (
                                <MDBBtn onClick={toggleChallengeOpen} style={{ marginRight: '15px', backgroundColor: "#f9b934" }}><MDBIcon fas icon="plus-circle" style={{ marginRight: '5px' }} />New Challenge</MDBBtn>
                            ) : (
                                <MDBBtn onClick={scrollToChallenge} style={{ marginRight: '15px' }}><MDBIcon fas icon="plus-circle" style={{ marginRight: '5px' }} />Register To A Challenge</MDBBtn>
                            )}


                            {props.user?.userType == 2 ? (
                                <MDBBtn onClick={scrollToChallenge}><MDBIcon fas icon="eye" style={{ marginRight: '5px' }} />Manage Challenges</MDBBtn>
                            ) : null}

                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow>
                    <MDBCol lg="4">
                        <MDBCard className="mb-4">
                            <MDBCardBody className="text-center">
                                <MDBCardImage
                                    src={props.user?.photoURL || "https://firebasestorage.googleapis.com/v0/b/unitech-fd90b.appspot.com/o/public%2FCode.PNG?alt=media&token=263e1b43-c87c-4b87-bd96-77112112b077"}
                                    alt="avatar"
                                    className="rounded-circle"
                                    style={{ width: '150px' }}
                                    fluid />
                                <p className="text-muted mb-1">{props.user?.occupation || "Occupation Not Entered"}</p>
                                <p className="text-muted mb-4">{props.user?.address || "Address not entered"}</p>
                                <div className="d-flex justify-content-center mb-2">
                                    <MDBBtn>Profile Link</MDBBtn>
                                    <MDBBtn outline className="ms-1">Message</MDBBtn>
                                </div>
                            </MDBCardBody>
                        </MDBCard>

                        <MDBCard className="mb-4 mb-lg-0">
                            <MDBCardBody className="p-0">
                                <MDBListGroup flush className="rounded-3">
                                    <MDBListGroupItem className="d-flex justify-content-between align-items-center p-3">
                                        <MDBIcon fas icon="globe fa-lg text-warning" />
                                        <MDBCardText>{props.user?.portfolio || "Website Not Entered"}</MDBCardText>
                                    </MDBListGroupItem>
                                    <MDBListGroupItem className="d-flex justify-content-between align-items-center p-3">
                                        <MDBIcon fab icon="github fa-lg" style={{ color: '#333333' }} />
                                        <MDBCardText>{props.user?.github || "Github Not Entered"}</MDBCardText>
                                    </MDBListGroupItem>
                                    <MDBListGroupItem className="d-flex justify-content-between align-items-center p-3">
                                        <MDBIcon fab icon="twitter fa-lg" style={{ color: '#55acee' }} />
                                        <MDBCardText>{props.user?.twitter || "Twitter (X) Not Entered"}</MDBCardText>
                                    </MDBListGroupItem>
                                    <MDBListGroupItem className="d-flex justify-content-between align-items-center p-3">
                                        <MDBIcon fab icon="instagram fa-lg" style={{ color: '#ac2bac' }} />
                                        <MDBCardText>{props.user?.whatsapp || "WhatsApp Not Entered"}</MDBCardText>
                                    </MDBListGroupItem>
                                    <MDBListGroupItem className="d-flex justify-content-between align-items-center p-3">
                                        <MDBIcon fab icon="facebook fa-lg" style={{ color: '#3b5998' }} />
                                        <MDBCardText>{props.user?.facebook || "Facebook Not Entered"}</MDBCardText>
                                    </MDBListGroupItem>
                                </MDBListGroup>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    <MDBCol lg="8">
                        <MDBCard className="mb-4">
                            <MDBCardBody>
                                <MDBRow>
                                    <MDBCol sm="3">
                                        <MDBCardText>Full Name</MDBCardText>
                                    </MDBCol>
                                    <MDBCol sm="9">
                                        <MDBCardText className="text-muted">{props.user?.displayName}</MDBCardText>
                                    </MDBCol>
                                </MDBRow>
                                <hr />
                                <MDBRow>
                                    <MDBCol sm="3">
                                        <MDBCardText>Email</MDBCardText>
                                    </MDBCol>
                                    <MDBCol sm="9">
                                        <MDBCardText className="text-muted">{props.user?.email}</MDBCardText>
                                    </MDBCol>
                                </MDBRow>
                                <hr />
                                <MDBRow>
                                    <MDBCol sm="3">
                                        <MDBCardText>Phone</MDBCardText>
                                    </MDBCol>
                                    <MDBCol sm="9">
                                        <MDBCardText className="text-muted">{props.user?.phoneNumber}</MDBCardText>
                                    </MDBCol>
                                </MDBRow>
                                {/* <hr />
                                <MDBRow>
                                <MDBCol sm="3">
                                    <MDBCardText>Mobile</MDBCardText>
                                </MDBCol>
                                <MDBCol sm="9">
                                    <MDBCardText className="text-muted">(098) 765-4321</MDBCardText>
                                </MDBCol>
                                </MDBRow> */}
                                <hr />
                                <MDBRow>
                                    <MDBCol sm="3">
                                        <MDBCardText>Address</MDBCardText>
                                    </MDBCol>
                                    <MDBCol sm="9">
                                        <MDBCardText className="text-muted">{props.user?.address}</MDBCardText>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCardBody>
                            <MDBCardFooter>
                                <MDBRow>
                                    <MDBCol sm="3">
                                        <MDBBtn outline className="ms-1" onClick={toggleOpen}>Edit<MDBIcon fas icon="user-edit" /></MDBBtn>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCardFooter>
                        </MDBCard>

                        <MDBRow>
                            <MDBCol md="12" lg="12" className="mb-2">
                                <MDBCard className="mb-4 mb-md-0">
                                    <MDBCardBody>
                                        <MDBCardText className="mb-4 d-flex flex-row justify-content-between">
                                            <div>
                                                My Articles
                                            </div>
                                            <div onClick={toggleArticleOpen} className="text-primary font-italic me-1" style={{ cursor: 'pointer' }}>
                                                New Article <MDBIcon fas icon="plus-circle" />
                                            </div>
                                        </MDBCardText>
                                        {/* <div dangerouslySetInnerHTML={{ __html: art.body }} /> */}
                                        {articles?.map((art, id) => {
                                            return (
                                                <MDBContainer className="py-1">
                                                    <MDBRow className="gx-5 border-bottom pb-4 mb-1">
                                                        <MDBCol md="6" className="mb-4">
                                                            <MDBRipple
                                                                className="bg-image hover-overlay ripple shadow-2-strong rounded-5"
                                                                rippleTag="div"
                                                                rippleColor="light"
                                                            >
                                                                <img
                                                                    src={art.coverImg}
                                                                    className="w-100"
                                                                />
                                                                <a href="#!">
                                                                    <div
                                                                        className="mask"
                                                                        style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
                                                                    ></div>
                                                                </a>
                                                            </MDBRipple>
                                                        </MDBCol>
                                                        <MDBCol md="6" className="mb-1">
                                                            {/* <span className="badge bg-danger px-2 py-1 shadow-1-strong mb-3">
                                                                News of the day
                                                            </span> */}
                                                            <h4>
                                                                <strong>{art.title}</strong>
                                                            </h4>
                                                            <p className="text-muted" style={{ height: '150px', overflowY: 'hidden' }}>
                                                                {art.description}
                                                            </p>
                                                            <Link to={`/article/${art.id}`}><MDBBtn>Read More</MDBBtn></Link>
                                                        </MDBCol>
                                                    </MDBRow>
                                                </MDBContainer>
                                            )
                                        })}
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>

                            {/* <MDBCol md="12" lg="12" className="mb-5">
                                <MDBCard className="mb-4 mb-md-0">
                                    <MDBCardBody>
                                        <MDBCardText className="mb-4 d-flex flex-row justify-content-between">
                                            <div>
                                                My Projects
                                            </div>
                                            <div onClick={toggleProjectOpen} className="text-primary font-italic me-1" style={{ cursor: 'pointer' }}>
                                                Register To A Challenge <MDBIcon fas icon="plus-circle" />
                                            </div>
                                        </MDBCardText>
                                        {projects?.map((prjct, id) => {
                                            return (
                                                <MDBContainer className="py-1">
                                                    <MDBRow className="gx-5 border-bottom pb-4 mb-1">
                                                        <MDBCol md="6" className="mb-4">
                                                            <MDBRipple
                                                                className="bg-image hover-overlay ripple shadow-2-strong rounded-5"
                                                                rippleTag="div"
                                                                rippleColor="light"
                                                            >
                                                                <img
                                                                    src={prjct.coverImg}
                                                                    className="w-100"
                                                                />
                                                                <a href="#!">
                                                                    <div
                                                                        className="mask"
                                                                        style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
                                                                    ></div>
                                                                </a>
                                                            </MDBRipple>
                                                        </MDBCol>
                                                        <MDBCol md="6" className="mb-1">
                                                            <h4>
                                                                <strong>{prjct.title}</strong>
                                                            </h4>
                                                            <p className="text-muted" style={{ height: '150px', overflowY: 'hidden' }}>
                                                                {prjct.description}
                                                            </p>
                                                            <MDBBtn><Link to={`/project/${prjct.id}`}>Read More</Link></MDBBtn>
                                                        </MDBCol>
                                                    </MDBRow>
                                                </MDBContainer>
                                            )
                                        })}
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol> */}
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
                <MDBRow id="challenges">
                    <MDBCard>
                        <MDBCardText className="p-3">Current Challenges</MDBCardText>
                        <MDBAccordion initialActive={1}>
                            {challenges.map((ch, id) => {
                                var index = id + 1;
                                return (<MDBAccordionItem collapseId={index} key={"c" + index} headerTitle={<><MDBIcon fas icon="question-circle" /> &nbsp; {ch.title}</>}>
                                    <p>{ch.description}</p>
                                    <Link to={`/challenge/${ch.id}`}><MDBBtn>Read More</MDBBtn></Link>
                                </MDBAccordionItem>)
                            })}
                        </MDBAccordion>
                    </MDBCard>
                </MDBRow>
            </MDBContainer>
            <ArticleEditor showArticleEdit={showArticleEdit} toggleArticleOpen={toggleArticleOpen} article={article} {...props} />
            <ChallengeEditor showChallengeEdit={showChallengeEdit} toggleChallengeOpen={toggleChallengeOpen} challenge={challenges} {...props} />
            <UserDetailsUpdate showDetailsUpdate={showDetailsUpdate} toggleOpen={toggleOpen} {...props} />
        </section>
    );
}

function UserDetailsUpdate(props) {

    const [email, setEmail] = React.useState("");
    const [firstName, setFirstName] = React.useState("");
    const [displayName, setDisplayName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [phoneNumber, setPhoneNumber] = React.useState("");
    const [address, setAddress] = React.useState("");
    const [portfolio, setPortfolio] = React.useState("");
    const [github, setGithub] = React.useState("");
    const [twitter, setTwitter] = React.useState("");
    const [whatsapp, setWhatsapp] = React.useState("");
    const [facebook, setFacebook] = React.useState("");
    const [occupation, setOccupation] = React.useState("");
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        initInfo();
    }, []);

    const initInfo = () => {
        setEmail(props.user?.email)
        setFirstName(props.user?.firstName)
        setLastName(props.user?.lastName)
        setDisplayName(props.user?.displayName)
        setPhoneNumber(props.user?.phoneNumber)
        setAddress(props.user?.address)
        setPortfolio(props.user?.portfolio)
        setGithub(props.user?.github)
        setTwitter(props.user?.twitter)
        setWhatsapp(props.user?.whatsapp)
        setFacebook(props.user?.facebook);
        setOccupation(props.user?.occupation);
        setLoading(false);
        console.log("user: ", props.user)
    }

    const updateInfo = async () => {
        var user = {
            uid: props.user?.uid,
            displayName: firstName + " " + lastName,
            firstName: firstName,
            lastName: lastName,
            email: email,
            phoneNumber: phoneNumber,
            address: address,
            facebook: facebook,
            whatsapp: whatsapp,
            twitter: twitter,
            github: github,
            portfolio: portfolio,
            occupation: occupation
        }

        await firebaseApi.updateUserData(user).then(res => {
            console.log("dialog updateUserData: ", res);
            props.setUser({ ...props.user, ...user });
            props.toggleOpen();

        })
    }

    return (
        <MDBModal staticBackdrop open={props.showDetailsUpdate} tabIndex='-1'>
            <MDBModalDialog size="lg">
                <MDBModalContent>
                    <MDBModalHeader>
                        <MDBModalTitle>Info Edit</MDBModalTitle>
                        <MDBBtn className='btn-close' color='none' onClick={props.toggleOpen}></MDBBtn>
                    </MDBModalHeader>
                    <MDBModalBody>
                        <MDBContainer fluid className="p-3 my-5">
                            {!loading ? (
                                <MDBRow>
                                    <MDBCol col='6' md='6' sm='12'>
                                        <MDBInput wrapperClass='mb-4' label='FirstName' id='firstname' type='text' size="md"
                                            value={firstName} onChange={(e) => setFirstName(e.target.value)} />

                                        <MDBInput wrapperClass='mb-4' label='LastName' id='lastname' type='text' size="md"
                                            value={lastName} onChange={(e) => setLastName(e.target.value)} />

                                        <MDBInput wrapperClass='mb-4' label='DisplayName' id='displayname' type='text' size="md"
                                            value={displayName} onChange={(e) => setDisplayName(e.target.value)} />


                                        <MDBInput wrapperClass='mb-4' label='Email address' id='email' type='email' size="md"
                                            value={email} onChange={(e) => setEmail(e.target.value)} />


                                        <MDBInput wrapperClass='mb-4' label='Phone' id='phone' type='tel' size="md"
                                            value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />

                                        <MDBInput wrapperClass='mb-4' label='Address' id='address' type='text' size="md"
                                            value={address} onChange={(e) => setAddress(e.target.value)} />

                                    </MDBCol>

                                    <MDBCol col='6' md='6' sm='12'>

                                        <MDBInput wrapperClass='mb-4' label='Occupation' id='occupation' type='text' size="md"
                                            value={occupation} onChange={(e) => setOccupation(e.target.value)} />

                                        <MDBInput wrapperClass='mb-4' label='Website/Portfolio' id='website' type='text' size="md"
                                            value={portfolio} onChange={(e) => setPortfolio(e.target.value)} />

                                        <MDBInput wrapperClass='mb-4' label='WhatsApp' id='whatsapp' type='text' size="md"
                                            value={whatsapp} onChange={(e) => setWhatsapp(e.target.value)} />


                                        <MDBInput wrapperClass='mb-4' label='Facebook' id='facebook' type='text' size="md"
                                            value={facebook} onChange={(e) => setFacebook(e.target.value)} />


                                        <MDBInput wrapperClass='mb-4' label='Twitter' id='twitter' type='text' size="md"
                                            value={twitter} onChange={(e) => setTwitter(e.target.value)} />

                                        <MDBInput wrapperClass='mb-4' label='Github' id='github' type='text' size="md"
                                            value={github} onChange={(e) => setGithub(e.target.value)} />

                                    </MDBCol>

                                </MDBRow>) : (
                                <MDBSpinner grow>
                                    <span className='visually-hidden'>Loading...</span>
                                </MDBSpinner>

                            )}

                        </MDBContainer>
                    </MDBModalBody>

                    <MDBModalFooter>
                        <MDBBtn color='secondary' onClick={props.toggleOpen}>
                            Cancel
                        </MDBBtn>
                        <MDBBtn onClick={updateInfo}>Save changes</MDBBtn>
                    </MDBModalFooter>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    );
}

function ChallengeEditor(props) {
    const [challenges, setChallenges] = React.useState("");
    const [challengeTitle, setChallengeTitle] = React.useState("");
    const [challengeDesc, setChallengeDesc] = React.useState("");
    const [challengeBody, setChallengeBody] = React.useState("");
    const [numberOfParticipant, setNumberOfParticipant] = React.useState(4);
    const [numberOfGroups, setNumberOfGroups] = React.useState(10);
    const [challengeImages, setChallengeImages] = React.useState([]);
    const [uploadErr, setUploadErr] = React.useState([]);
    const [editorState, setEditorState] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [selectedImagePrev, setSelectedImagePrev] = React.useState(null);
    const [selectedImage, setSelectedImage] = React.useState(null);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedImage(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedImagePrev(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    React.useEffect(() => {

        setChallengeTitle(props.challenge?.title || "");
        setChallengeBody(props.challenge?.body || "<div></div>");

        const contentBlock = htmlToDraft(props.challenge?.body || "<div></div>");
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState);
        }
        setLoading(false);
    }, [])

    const onContentStateChange = (editorState) => {
        var html = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        console.log("editorState: ", editorState);
        console.log("html: ", html);
        setChallengeBody(html);
        setEditorState(editorState);
    }


    const generateRandomString = () => {
        const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        const charactersLength = charset.length;
        for (let i = 0; i < 10; i++) {
            result += charset.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    const uploadFile = (file) => {
        console.log("image upload: ", file)
        // Add the uploaded image file to the array of uploaded images
        setChallengeImages([...challengeImages, file]);

        // Create a local read link for the image
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve({ data: { link: reader.result } });
            };
            reader.readAsDataURL(file);
        });
    }

    const updateChallenge = async () => {
        if (props.challenge?.id) {
            var articl = {
                title: challengeTitle,
                description: challengeDesc,
                coverImg: "",
                body: challengeBody,
                images: challengeImages,
                uid: props.user.uid,
                id: props.challenge?.id
            }

            handleChallengeCover(articl);

        } else {
            let id = generateRandomString();
            let idAvailable = false;

            while (!idAvailable) {
                const res = await firebaseApi.checkIdAvailability(id, 'user-challenges');

                if (!res.exist) {
                    // ID is not taken
                    idAvailable = true;
                    var articl = {
                        title: challengeTitle,
                        description: challengeDesc,
                        coverImg: "",
                        body: challengeBody,
                        images: challengeImages,
                        uid: props.user.uid,
                        id: id
                    }
                    handleChallengeCover(articl);

                } else {
                    // ID exists, generate a new one and check again
                    id = generateRandomString();
                }
            }
        }

    }

    const handleChallengeCover = async (articl) => {

        if (selectedImage) {
            firebaseApi.uploadFile({
                fullPath: `challenges/${Math.floor(Math.random() * (999 - 100 + 1)) + 100}-${selectedImage.name}`,
                file: selectedImage
            }, (res) => {
                if (!res.status) {
                    setUploadErr([...uploadErr, { ...res.error }])
                    articl.coverImg = "";
                } else {
                    articl.coverImg = res.url;
                }
                handleChallengeSave(articl);
            })
        } else {
            handleChallengeSave(articl);
        }

    }

    const handleChallengeSave = async (articl) => {

        if (challengeImages.length > 0) {
            handleUploadSteps(0, articl)
        } else {
            firebaseApi.updateUserChallenge(articl).then((artRes) => {
                props.toggleChallengeOpen();
            })
        }

    }

    const handleUploadSteps = (index, articl) => {
        if (challengeImages[index]) {
            firebaseApi.uploadFile({
                fullPath: `challenges/${Math.floor(Math.random() * (999 - 100 + 1)) + 100}-${challengeImages[index].name}`,
                file: challengeImages[index]
            }, (res) => {
                if (!res.status) {
                    setUploadErr([...uploadErr, { ...res.error }])
                    articl.images[index] = "";
                } else {
                    articl.images[index] = res.url;
                }

                var nIndex = index + 1;
                handleUploadSteps(nIndex, articl)
            })
        } else {
            firebaseApi.updateUserChallenge(articl).then((artRes) => {
                props.toggleChallengeOpen();
            })
        }
    }

    return (
        <MDBModal open={props.showChallengeEdit} tabIndex='-1'>
            <MDBModalDialog size="xl" style={{ backgroundColor: "#dce6fa" }}>
                <MDBModalContent >
                    <MDBModalHeader style={{ backgroundColor: "rgba(72, 88, 120)", color: "white" }}>
                        <MDBModalTitle style={{ color: 'white' }}>Challenge</MDBModalTitle>
                        <MDBBtn className='btn-close' onClick={props.toggleChallengeOpen} style={{ color: 'white' }}></MDBBtn>
                    </MDBModalHeader>
                    <MDBModalBody>
                        <MDBContainer fluid className="p-3 my-1">

                            {!loading && (<MDBInput wrapperClass='mb-4' label='Challenge Title' id='title' type='text' size="md"
                                value={challengeTitle} onChange={(e) => setChallengeTitle(e.target.value)} />)}

                            {!loading && (<MDBTextArea rows="3" wrapperClass='mb-4' label='Challenge Short Description' id='title' type='text' size="md"
                                value={challengeDesc} onChange={(e) => setChallengeDesc(e.target.value)} />)}

                            {!loading && (<MDBInput wrapperClass='mb-4' label='Max Number Per Groupe' id='np' type='number' min="1" size="md"
                                value={numberOfParticipant} onChange={(e) => setNumberOfParticipant(e.target.value)} />)}

                            {!loading && (<MDBInput wrapperClass='mb-4' label='Number of Groupe' id='np' type='number' min="10" size="md"
                                value={numberOfGroups} onChange={(e) => setNumberOfGroups(e.target.value)} />)}

                            {!loading && (
                                <MDBRow className='mt-2 mb-4'>
                                    <MDBCard>
                                        <label htmlFor='imageInput' className='form-label mb-2'>
                                            Select Challenge Cover Image:
                                        </label>
                                        <input
                                            id='imageInput'
                                            type='file'
                                            accept='image/*'
                                            onChange={handleImageChange}
                                            className='form-control mb-3'
                                        />
                                        {selectedImagePrev && (
                                            <div className='mt-2 mb-2'>
                                                <img src={selectedImagePrev} style={{ maxWidth: '250px', maxHeight: '200px' }} alt='Selected' className='img-fluid rounded' />
                                            </div>
                                        )}
                                    </MDBCard>
                                </MDBRow>
                            )}

                            {!loading ? (
                                <MDBCard className='mt-2 mb-4'>
                                    <Editor
                                        editorState={editorState}
                                        onEditorStateChange={onContentStateChange}
                                        toolbar={{
                                            image: {
                                                urlEnabled: true,
                                                uploadEnabled: true,
                                                uploadCallback: uploadFile
                                            }
                                        }}
                                    />
                                </MDBCard>
                            ) : (
                                <MDBSpinner grow>
                                    <span className='visually-hidden'>Loading...</span>
                                </MDBSpinner>

                            )}

                        </MDBContainer>
                    </MDBModalBody>

                    <MDBModalFooter>
                        <MDBBtn color='secondary' onClick={props.toggleChallengeOpen}>
                            Cancel
                        </MDBBtn>
                        <MDBBtn onClick={updateChallenge}>Save changes</MDBBtn>
                    </MDBModalFooter>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    )
}

function ArticleEditor(props) {
    const [article, setArticle] = React.useState("");
    const [articleTitle, setArticleTitle] = React.useState("");
    const [articleDesc, setArticleDesc] = React.useState("");
    const [articleBody, setArticleBody] = React.useState("");
    const [articleImages, setArticleImages] = React.useState([]);
    const [uploadErr, setUploadErr] = React.useState([]);
    const [editorState, setEditorState] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [selectedImagePrev, setSelectedImagePrev] = React.useState(null);
    const [selectedImage, setSelectedImage] = React.useState(null);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedImage(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedImagePrev(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    React.useEffect(() => {

        setArticleTitle(props.article?.title || "");
        setArticleBody(props.article?.body || "<div></div>");

        const contentBlock = htmlToDraft(props.article?.body || "<div></div>");
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState);
        }
        setLoading(false);
    }, [])

    const onContentStateChange = (editorState) => {
        var html = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        console.log("editorState: ", editorState);
        console.log("html: ", html);
        setArticleBody(html);
        setEditorState(editorState);
    }


    const generateRandomString = () => {
        const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        const charactersLength = charset.length;
        for (let i = 0; i < 10; i++) {
            result += charset.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    const uploadFile = (file) => {
        console.log("image upload: ", file)
        // Add the uploaded image file to the array of uploaded images
        setArticleImages([...articleImages, file]);

        // Create a local read link for the image
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve({ data: { link: reader.result } });
            };
            reader.readAsDataURL(file);
        });
    }

    const updateArticle = async () => {
        if (props.article?.id) {
            var articl = {
                title: articleTitle,
                description: articleDesc,
                coverImg: "",
                body: articleBody,
                images: articleImages,
                uid: props.user.uid,
                id: props.article?.id
            }

            handleArticleCover(articl);

        } else {
            let id = generateRandomString();
            let idAvailable = false;

            while (!idAvailable) {
                const res = await firebaseApi.checkIdAvailability(id, 'user-articles');

                if (!res.exist) {
                    // ID is not taken
                    idAvailable = true;
                    var articl = {
                        title: articleTitle,
                        description: articleDesc,
                        coverImg: "",
                        body: articleBody,
                        images: articleImages,
                        uid: props.user.uid,
                        id: id
                    }
                    handleArticleCover(articl);

                } else {
                    // ID exists, generate a new one and check again
                    id = generateRandomString();
                }
            }
        }

    }

    const handleArticleCover = async (articl) => {

        if (selectedImage) {
            firebaseApi.uploadFile({
                fullPath: `articles/${Math.floor(Math.random() * (999 - 100 + 1)) + 100}-${selectedImage.name}`,
                file: selectedImage
            }, (res) => {
                if (!res.status) {
                    setUploadErr([...uploadErr, { ...res.error }])
                    articl.coverImg = "";
                } else {
                    articl.coverImg = res.url;
                }
                handleArticleSave(articl);
            })
        } else {
            handleArticleSave(articl);
        }

    }

    const handleArticleSave = async (articl) => {

        if (articleImages.length > 0) {
            handleUploadSteps(0, articl)
        } else {
            firebaseApi.updateUserArticle(articl).then((artRes) => {
                props.toggleArticleOpen();
            })
        }

    }

    const handleUploadSteps = (index, articl) => {
        if (articleImages[index]) {
            firebaseApi.uploadFile({
                fullPath: `articles/${Math.floor(Math.random() * (999 - 100 + 1)) + 100}-${articleImages[index].name}`,
                file: articleImages[index]
            }, (res) => {
                if (!res.status) {
                    setUploadErr([...uploadErr, { ...res.error }])
                    articl.images[index] = "";
                } else {
                    articl.images[index] = res.url;
                }

                var nIndex = index + 1;
                handleUploadSteps(nIndex, articl)
            })
        } else {
            firebaseApi.updateUserArticle(articl).then((artRes) => {
                props.toggleArticleOpen();
            })
        }
    }

    return (
        <MDBModal open={props.showArticleEdit} tabIndex='-1'>
            <MDBModalDialog size="xl" style={{ backgroundColor: "#dce6fa" }}>
                <MDBModalContent >
                    <MDBModalHeader style={{ backgroundColor: "rgba(72, 88, 120)", color: "white" }}>
                        <MDBModalTitle style={{ color: 'white' }}>Article</MDBModalTitle>
                        <MDBBtn className='btn-close' onClick={props.toggleArticleOpen} style={{ color: 'white' }}></MDBBtn>
                    </MDBModalHeader>
                    <MDBModalBody>
                        <MDBContainer fluid className="p-3 my-1">

                            {!loading && (<MDBInput wrapperClass='mb-4' label='Article Title' id='title' type='text' size="md"
                                value={articleTitle} onChange={(e) => setArticleTitle(e.target.value)} />)}

                            {!loading && (<MDBTextArea rows="3" wrapperClass='mb-4' label='Article Short Description' id='title' type='text' size="md"
                                value={articleDesc} onChange={(e) => setArticleDesc(e.target.value)} />)}


                            {!loading && (
                                <MDBRow className='mt-2 mb-4'>
                                    <MDBCard>
                                        <label htmlFor='imageInput' className='form-label mb-2'>
                                            Select Article Cover Image:
                                        </label>
                                        <input
                                            id='imageInput'
                                            type='file'
                                            accept='image/*'
                                            onChange={handleImageChange}
                                            className='form-control mb-3'
                                        />
                                        {selectedImagePrev && (
                                            <div className='mt-2 mb-2'>
                                                <img src={selectedImagePrev} style={{ maxWidth: '250px', maxHeight: '200px' }} alt='Selected' className='img-fluid rounded' />
                                            </div>
                                        )}
                                    </MDBCard>
                                </MDBRow>
                            )}

                            {!loading ? (
                                <MDBCard className='mt-2 mb-4'>
                                    <Editor
                                        editorState={editorState}
                                        onEditorStateChange={onContentStateChange}
                                        toolbar={{
                                            image: {
                                                urlEnabled: true,
                                                uploadEnabled: true,
                                                uploadCallback: uploadFile
                                            }
                                        }}
                                    />
                                </MDBCard>
                            ) : (
                                <MDBSpinner grow>
                                    <span className='visually-hidden'>Loading...</span>
                                </MDBSpinner>

                            )}

                        </MDBContainer>
                    </MDBModalBody>

                    <MDBModalFooter>
                        <MDBBtn color='secondary' onClick={props.toggleArticleOpen}>
                            Cancel
                        </MDBBtn>
                        <MDBBtn onClick={updateArticle}>Save changes</MDBBtn>
                    </MDBModalFooter>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    )
}

var toolbarOpt = {
    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
    inline: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
        options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript'],
        bold: { icon: "bold", className: undefined },
        italic: { icon: "italic", className: undefined },
        underline: { icon: "underline", className: undefined },
        strikethrough: { icon: "strikethrough", className: undefined },
        monospace: { icon: "monospace", className: undefined },
        superscript: { icon: "superscript", className: undefined },
        subscript: { icon: "subscript", className: undefined },
    },
    blockType: {
        inDropdown: true,
        options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
    },
    fontSize: {
        icon: "fontSize",
        options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
    },
    fontFamily: {
        options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
    },
    list: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
        options: ['unordered', 'ordered', 'indent', 'outdent'],
        unordered: { icon: "unordered", className: undefined },
        ordered: { icon: "ordered", className: undefined },
        indent: { icon: "indent", className: undefined },
        outdent: { icon: "outdent", className: undefined },
    },
    textAlign: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
        options: ['left', 'center', 'right', 'justify'],
        left: { icon: "left", className: undefined },
        center: { icon: "center", className: undefined },
        right: { icon: "right", className: undefined },
        justify: { icon: "justify", className: undefined },
    },
    colorPicker: {
        icon: "color",
        className: undefined,
        component: undefined,
        popupClassName: undefined,
        colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
            'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
            'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
            'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
            'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
            'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
    },
    link: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        popupClassName: undefined,
        dropdownClassName: undefined,
        showOpenOptionOnHover: true,
        defaultTargetOption: '_self',
        options: ['link', 'unlink'],
        link: { icon: "link", className: undefined },
        unlink: { icon: "unlink", className: undefined },
        linkCallback: undefined
    },
    emoji: {
        icon: "emoji",
        className: undefined,
        component: undefined,
        popupClassName: undefined,
        emojis: [
            '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
            '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
            '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
            '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
            '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
            '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
            '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
            '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
            '✅', '❎', '💯',
        ],
    },
    embedded: {
        icon: "embedded",
        className: undefined,
        component: undefined,
        popupClassName: undefined,
        embedCallback: undefined,
        defaultSize: {
            height: 'auto',
            width: 'auto',
        },
    },
    image: {
        icon: "image",
        className: "",
        component: "",
        popupClassName: "",
        urlEnabled: true,
        uploadEnabled: false,
        alignmentEnabled: true,
        uploadCallback: undefined,
        previewImage: false,
        inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
        alt: { present: false, mandatory: false },
        defaultSize: {
            height: 'auto',
            width: 'auto',
        },
    },
    remove: { icon: "eraser", className: undefined, component: undefined },
    history: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
        options: ['undo', 'redo'],
        undo: { icon: "undo", className: undefined },
        redo: { icon: "redo", className: undefined },
    },
}