import React from 'react';
import './home.css';

function Home(props) {

  React.useEffect(() => {
    if(props.section){
        const element = document.getElementById(props.section);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }
  }, [props.section])


  return (
    <div>
      <div>

        <section id="hero" className="d-flex align-items-center">

          <div className="container">
            <div className="row">
              <div className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
                <h3>Connecting Tech Minds: Join Our IT Community</h3>
                <h4>
                  Fostering collaboration and innovation at the intersection of technology and expertise.
                  Brilliant developers, DevOps professionals, Network specialists.
                </h4>
                <div className="d-flex justify-content-center justify-content-lg-start">
                  <a href="#about" className="btn-get-started scrollto">Join Us Today</a>
                </div>
              </div>
              <div className="col-lg-6 order-1 order-lg-2 hero-img" data-aos="zoom-in" data-aos-delay="200">
                <img src="assets/img/hero-img.png" className="img-fluid animated" alt="" />
              </div>
            </div>
          </div>

        </section>

        <main id="main">


          <section id="clients" className="clients section-bg">
            <div className="container">

              <div className="row" data-aos="zoom-in">

                <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                  <img src="assets/img/clients/dotnet.png" className="img-fluid" alt="" />
                </div>

                <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                  <img src="assets/img/clients/node.png" className="img-fluid" alt="" />
                </div>

                <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                  <img src="assets/img/clients/angular.png" className="img-fluid" alt="" />
                </div>

                <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                  <img src="assets/img/clients/react.png" className="img-fluid" alt="" />
                </div>

                <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                  <img src="assets/img/clients/flutter.png" className="img-fluid" alt="" />
                </div>

                <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                  <img src="assets/img/clients/cloud.png" className="img-fluid" alt="" />
                </div>

                <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                  <img src="assets/img/clients/db.png" className="img-fluid" alt="" />
                </div>

                <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                  <img src="assets/img/clients/docker.png" className="img-fluid" alt="" />
                </div>

                <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                  <img src="assets/img/clients/Pihole.png" className="img-fluid" alt="" />
                </div>

                <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                  <img src="assets/img/clients/proxy.png" className="img-fluid" alt="" />
                </div>

                <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                  <img src="assets/img/clients/nginx.png" className="img-fluid" alt="" />
                </div>

                <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                  <img src="assets/img/clients/docker.png" className="img-fluid" alt="" />
                </div>

              </div>

            </div>
          </section>


          <section id="about" className="about">
            <div className="container" data-aos="fade-up">

              <div className="section-title">
                <h2>About Us</h2>
              </div>

              <div className="row content">
                <div className="col-lg-6">
                  <p>
                    Join a dynamic community where developers, DevOps enthusiasts, and Network specialists converge, sharing insights and pushing the boundaries of technological excellence together.
                  </p>
                  <ul>
                    <li><i className="ri-check-double-line"></i> Software Development</li>
                    <li><i className="ri-check-double-line"></i> Cloud Computing</li>
                    <li><i className="ri-check-double-line"></i> Virtualization</li>
                  </ul>
                </div>
                <div className="col-lg-6 pt-4 pt-lg-0">
                  <p>
                    Celebrating the convergence of diverse expertise, our community serves as a vibrant hub where developers, DevOps practitioners, and Network specialists converge, exchanging knowledge, cultivating innovation, and collectively navigating the ever-evolving landscape of technology. Embrace the synergy of collaboration, access a wealth of insights, and forge meaningful connections in our inclusive space dedicated to fostering growth, problem-solving, and advancing the collective expertise of IT professionals across various domains.
                  </p>
                  <a href="#" className="btn-learn-more">Learn More</a>
                </div>
              </div>

            </div>
          </section>

          <section id="why-us" className="why-us section-bg">
            <div className="container-fluid" data-aos="fade-up">

              <div className="row">

                <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">

                  <div className="content">
                    <h3>Unitech <strong>Community</strong></h3>
                    <p>
                      Who are we and What do we do?
                    </p>
                  </div>

                  <div className="accordion-list">
                    <ul>
                      <li>
                        <a data-bs-toggle="collapse" className="collapsed" data-bs-target="#accordion-list-1"><span>01</span> Software Development - DevOps <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                        <div id="accordion-list-1" className="collapse show" data-bs-parent=".accordion-list">

                          <div>
                            <p className="justified">
                              Hey there, fellow developer! Ever wondered what goes into crafting those cool apps and programs we use every day? At our community, we're all about diving deep into the fascinating journey of software development.
                            </p>
                            <p className="justified">
                              Think of it as a creative process where we start by understanding what users really want. From there, we roll up our sleeves and get coding using different tools and languages—kind of like building with Lego bricks, but in the digital world!
                            </p>
                            <p className="justified">
                              We're not just about the serious stuff though. We're all about trying out different approaches, sharing tips and tricks, and making sure the stuff we create works like a charm. Bugs? Yeah, we squash them before they even think of bothering our awesome creations!
                            </p>
                            <p className="justified">
                              So, if you're keen on joining a bunch of passionate developers like yourself, eager to learn, grow, and create awesome software together, then you're in the right place. Come join our community where we blend creativity with problem-solving and have a blast making magic with software!
                            </p>
                          </div>
                        </div>
                      </li>

                      <li>
                        <a data-bs-toggle="collapse" data-bs-target="#accordion-list-2" className="collapsed"><span>02</span> Cloud Computing <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                        <div id="accordion-list-2" className="collapse" data-bs-parent=".accordion-list">
                          <div>
                            <p className="justified">
                              Unveiling the Power of Cloud Computing: Join Our Community!
                            </p>
                            <p className="justified">
                              Cloud computing – it’s not just a buzzword; it's a game-changer! Here at our community, we're all about exploring the wonders of cloud technology.
                            </p>
                            <p className="justified">
                              Picture this: instead of storing everything on your device, imagine having access to a magical, virtual space where you can store, manage, and access your data and applications from anywhere, anytime! That's the magic of the cloud.
                            </p>
                            <p className="justified">
                              We're here to demystify the cloud for you. It's like having your own digital playground where innovation thrives. We talk about storing stuff securely, running applications without a hitch, and scaling things up without breaking a sweat.
                            </p>
                            <p className="justified">
                              But hey, it's not all technical jargon! We're a bunch of enthusiasts who love discussing how the cloud changes the game for businesses, how it makes streaming services work flawlessly, and how it's shaping the future of technology.
                            </p>
                            <p className="justified">
                              So, if you're curious about the cloud, excited to learn, and eager to join a vibrant community of cloud enthusiasts, hop aboard! Let’s explore, share ideas, and ride the wave of cloud computing together!
                            </p>
                          </div>
                        </div>
                      </li>

                      <li>
                        <a data-bs-toggle="collapse" data-bs-target="#accordion-list-3" className="collapsed"><span>03</span> Virtualization <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                        <div id="accordion-list-3" className="collapse" data-bs-parent=".accordion-list">
                          <div>
                            <p className="justified">
                              Unlocking Possibilities with Virtualization: Join Our Community!
                            </p>
                            <p className="justified">
                              Virtualization? It's like having a superpower for computers! Imagine running multiple 'computers within computers' or several operating systems on a single machine. That's the magic of virtualization, and our community is all about diving deep into this incredible world.
                            </p>
                            <p className="justified">
                              Think of it as creating digital twins of your hardware and software. We're here to explore how this game-changing technology lets you do more with less – optimizing resources, improving efficiency, and making computing smarter.
                            </p>
                            <p className="justified">
                              We're not just about the technical nitty-gritty, though. We're passionate about discussing how virtualization revolutionizes data centers, enhances security, and simplifies IT management. Plus, we geek out over how it powers cloud computing and enables businesses to scale seamlessly.
                            </p>
                            <p className="justified">
                              So, if you're intrigued by the idea of virtualization, eager to learn, and excited to be a part of a community where we unravel its endless possibilities, then join us! Let’s dive into this virtual realm together, share insights, and harness the potential of virtualization technology!"
                            </p>
                          </div>
                        </div>
                      </li>

                    </ul>
                  </div>

                </div>

                <div className="col-lg-5 align-items-stretch order-1 order-lg-2 img" style={{ backgroundImage: 'url("assets/img/why-us.png")' }} data-aos="zoom-in" data-aos-delay="150">&nbsp;</div>
              </div>

            </div>
          </section>

          {/* <section id="skills" className="skills">
    <div className="container" data-aos="fade-up">

      <div className="row">
        <div className="col-lg-6 d-flex align-items-center" data-aos="fade-right" data-aos-delay="100">
          <img src="assets/img/skills.png" className="img-fluid" alt="" />
        </div>
        <div className="col-lg-6 pt-4 pt-lg-0 content" data-aos="fade-left" data-aos-delay="100">
          <h3>Voluptatem dignissimos provident quasi corporis voluptates</h3>
          <p className="fst-italic">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
            magna aliqua.
          </p>

          <div className="skills-content">

            <div className="progress">
              <span className="skill">HTML <i className="val">100%</i></span>
              <div className="progress-bar-wrap">
                <div className="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>

            <div className="progress">
              <span className="skill">CSS <i className="val">90%</i></span>
              <div className="progress-bar-wrap">
                <div className="progress-bar" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>

            <div className="progress">
              <span className="skill">JavaScript <i className="val">75%</i></span>
              <div className="progress-bar-wrap">
                <div className="progress-bar" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>

            <div className="progress">
              <span className="skill">Photoshop <i className="val">55%</i></span>
              <div className="progress-bar-wrap">
                <div className="progress-bar" role="progressbar" aria-valuenow="55" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>

          </div>

        </div>
      </div>

    </div>
  </section> */}

          <section id="services" className="services section-bg">
            <div className="container" data-aos="fade-up">

              <div className="section-title">
                <h2>Activities</h2>
              </div>

              <div className="row d-flex justify-content-center">
                <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay="200">
                  <div className="icon-box">
                    <div className="icon"><i className="bx bx-file"></i></div>
                    <h4><a href="">Have a project? We have the solution!</a></h4>
                    <p className="justified">
                      Embarking on a virtualization, development, or cloud computing project?
                    </p>
                    <p className="justified">
                      Let our expertise be your guide. Contact us for tailored solutions that drive success in these cutting-edge domains.
                    </p>
                    <p className="justified">
                      Let's bring your project to life together!
                    </p>
                  </div>
                </div>

                <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="300">
                  <div className="icon-box">
                    <div className="icon"><i className="bx bx-tachometer"></i></div>
                    <h4><a href="">Need Some Training?</a></h4>
                    <p className="justified">
                      We offer private classes, mentorship and assistance in various domains such as in virtualization, cloud computing and software development.
                    </p>

                  </div>
                </div>

                <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="400">
                  <div className="icon-box">
                    <div className="icon"><i className="bx bx-layer"></i></div>
                    <h4><a href="">Upcoming: Unitech Challenge</a></h4>
                    <p className="justified">
                      With Unitech Challenge, you have a chance to show your skills.
                    </p>
                    <p className="justified">
                      Offer your solutions to some real life problem. We work with different companies to pick the topic.
                    </p>
                    <p className="justified">
                      The top 3 Winners are gettings compensated and may have a chance to implement their solution.
                    </p>
                    <p className="justified">
                      Become a member of Unitech Community today to compete.
                    </p>
                  </div>
                </div>

              </div>

            </div>
          </section>

          <section id="cta" className="cta">
            <div className="container" data-aos="zoom-in">

              <div className="row">
                <div className="col-lg-9 text-center text-lg-start">
                  <h3>Get In Touch Now</h3>
                  <p>
                    Interested in joining out community?
                  </p>
                </div>
                <div className="col-lg-3 cta-btn-container text-center">
                  <a className="cta-btn align-middle" href="#">Signup now!</a>
                </div>
              </div>

            </div>
          </section>

          <section id="portfolio" className="portfolio">
            <div className="container" data-aos="fade-up">

              <div className="section-title">
                <h2>Latest Realizations</h2>
              </div>

              {/* <ul id="portfolio-flters" className="d-flex justify-content-center" data-aos="fade-up" data-aos-delay="100">
        <li data-filter="*" className="filter-active">All</li>
        <li data-filter=".filter-app">App</li>
        <li data-filter=".filter-card">Card</li>
        <li data-filter=".filter-web">Web</li>
      </ul> */}

              <div className="row portfolio-container" data-aos="fade-up" data-aos-delay="200">

                <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                  <div className="portfolio-img"><img src="assets/img/portfolio/yennega movies.PNG" className="img-fluid" alt="" /></div>
                  <div className="portfolio-info">
                    <h4>Yennega Movies</h4>
                    <p>Website, Android and iOS Mobile, Fully integrated with payments subscription and admin panel.</p>
                    <a href="https://www.yennegamovie.com" className="details-link" title="More Details"><i className="bx bx-link"></i></a>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                  <div className="portfolio-img"><img src="assets/img/portfolio/freeyaar.png" className="img-fluid" alt="" /></div>
                  <div className="portfolio-info">
                    <h4>Free Yaar</h4>
                    <p>Website, full e-commerce website with admin panel.</p>
                    <a href="assets/img/portfolio/freeyaar.png" data-gallery="portfolioGallery" className="portfolio-lightbox preview-link" title="Web 3"><i className="bx bx-plus"></i></a>
                    <a href="https://www.freeyaar.com/" className="details-link" title="More Details"><i className="bx bx-link"></i></a>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                  <div className="portfolio-img"><img src="assets/img/portfolio/zunongo.PNG" className="img-fluid" alt="" /></div>
                  <div className="portfolio-info">
                    <h4>Zunungo Group LLC</h4>
                    <p>App</p>
                    <a href="assets/img/portfolio/zunongo.PNG" data-gallery="portfolioGallery" className="portfolio-lightbox preview-link" title="App 2"><i className="bx bx-plus"></i></a>
                    <a href="https://zunungo-group.com/" className="details-link" title="More Details"><i className="bx bx-link"></i></a>
                  </div>
                </div>

                {/* <div className="col-lg-4 col-md-6 portfolio-item filter-card">
          <div className="portfolio-img"><img src="assets/img/portfolio/portfolio-4.jpg" className="img-fluid" alt="" /></div>
          <div className="portfolio-info">
            <h4>Card 2</h4>
            <p>Card</p>
            <a href="assets/img/portfolio/portfolio-4.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox preview-link" title="Card 2"><i className="bx bx-plus"></i></a>
            <a href="portfolio-details.html" className="details-link" title="More Details"><i className="bx bx-link"></i></a>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 portfolio-item filter-web">
          <div className="portfolio-img"><img src="assets/img/portfolio/portfolio-5.jpg" className="img-fluid" alt="" /></div>
          <div className="portfolio-info">
            <h4>Web 2</h4>
            <p>Web</p>
            <a href="assets/img/portfolio/portfolio-5.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox preview-link" title="Web 2"><i className="bx bx-plus"></i></a>
            <a href="portfolio-details.html" className="details-link" title="More Details"><i className="bx bx-link"></i></a>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 portfolio-item filter-app">
          <div className="portfolio-img"><img src="assets/img/portfolio/portfolio-6.jpg" className="img-fluid" alt="" /></div>
          <div className="portfolio-info">
            <h4>App 3</h4>
            <p>App</p>
            <a href="assets/img/portfolio/portfolio-6.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox preview-link" title="App 3"><i className="bx bx-plus"></i></a>
            <a href="portfolio-details.html" className="details-link" title="More Details"><i className="bx bx-link"></i></a>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 portfolio-item filter-card">
          <div className="portfolio-img"><img src="assets/img/portfolio/portfolio-7.jpg" className="img-fluid" alt="" /></div>
          <div className="portfolio-info">
            <h4>Card 1</h4>
            <p>Card</p>
            <a href="assets/img/portfolio/portfolio-7.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox preview-link" title="Card 1"><i className="bx bx-plus"></i></a>
            <a href="portfolio-details.html" className="details-link" title="More Details"><i className="bx bx-link"></i></a>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 portfolio-item filter-card">
          <div className="portfolio-img"><img src="assets/img/portfolio/portfolio-8.jpg" className="img-fluid" alt="" /></div>
          <div className="portfolio-info">
            <h4>Card 3</h4>
            <p>Card</p>
            <a href="assets/img/portfolio/portfolio-8.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox preview-link" title="Card 3"><i className="bx bx-plus"></i></a>
            <a href="portfolio-details.html" className="details-link" title="More Details"><i className="bx bx-link"></i></a>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 portfolio-item filter-web">
          <div className="portfolio-img"><img src="assets/img/portfolio/portfolio-9.jpg" className="img-fluid" alt="" /></div>
          <div className="portfolio-info">
            <h4>Web 3</h4>
            <p>Web</p>
            <a href="assets/img/portfolio/portfolio-9.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox preview-link" title="Web 3"><i className="bx bx-plus"></i></a>
            <a href="portfolio-details.html" className="details-link" title="More Details"><i className="bx bx-link"></i></a>
          </div>
        </div> */}

              </div>

            </div>
          </section>

          <section id="team" className="team section-bg">
            <div className="container" data-aos="fade-up">

              <div className="section-title">
                <h2>Founders of Unitech Community</h2>
              </div>

              <div className="row">

                <div className="col-lg-6" data-aos="zoom-in" data-aos-delay="100">
                  <div className="member d-flex align-items-start">
                    <div className="pic"><img src="assets/img/team/Minoungou.jpeg" className="img-fluid" alt="" /></div>
                    <div className="member-info">
                      <h4>Thomas Minoungou</h4>
                      <span>Co-Founder</span>
                      <p>Full Time Software Developer at JPPC Inc</p>
                      <div className="social">

                        <a href="https://www.facebook.com/thomas.minoungou"><i className="ri-facebook-fill"></i></a>

                        <a href="https://www.linkedin.com/in/thomas-minoungou/"> <i className="ri-linkedin-box-fill"></i> </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 mt-4 mt-lg-0" data-aos="zoom-in" data-aos-delay="200">
                  <div className="member d-flex align-items-start">
                    <div className="pic"><img src="assets/img/team/traore.jpeg" className="img-fluid" alt="" /></div>
                    <div className="member-info">
                      <h4>Israel Traore</h4>
                      <span>Co-Founder</span>
                      <p>Full Time .Net Developer and Application Support At Wipro</p>
                      <div className="social">

                        <a href="https://www.facebook.com/louisyvan.traore"><i className="ri-facebook-fill"></i></a>

                        <a href="https://www.linkedin.com/in/traore-rabaswende-bb9903222/"> <i className="ri-linkedin-box-fill"></i> </a>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="col-lg-6 mt-4" data-aos="zoom-in" data-aos-delay="300">
          <div className="member d-flex align-items-start">
            <div className="pic"><img src="assets/img/team/team-3.jpg" className="img-fluid" alt="" /></div>
            <div className="member-info">
              <h4>William Anderson</h4>
              <span>CTO</span>
              <p>Quisquam facilis cum velit laborum corrupti fuga rerum quia</p>
              <div className="social">
                <a href=""><i className="ri-twitter-fill"></i></a>
                <a href=""><i className="ri-facebook-fill"></i></a>
                <a href=""><i className="ri-instagram-fill"></i></a>
                <a href=""> <i className="ri-linkedin-box-fill"></i> </a>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 mt-4" data-aos="zoom-in" data-aos-delay="400">
          <div className="member d-flex align-items-start">
            <div className="pic"><img src="assets/img/team/team-4.jpg" className="img-fluid" alt="" /></div>
            <div className="member-info">
              <h4>Amanda Jepson</h4>
              <span>Accountant</span>
              <p>Dolorum tempora officiis odit laborum officiis et et accusamus</p>
              <div className="social">
                <a href=""><i className="ri-twitter-fill"></i></a>
                <a href=""><i className="ri-facebook-fill"></i></a>
                <a href=""><i className="ri-instagram-fill"></i></a>
                <a href=""> <i className="ri-linkedin-box-fill"></i> </a>
              </div>
            </div>
          </div>
        </div> */}

              </div>

            </div>
          </section>

          {/* <section id="pricing" className="pricing">
    <div className="container" data-aos="fade-up">

      <div className="section-title">
        <h2>Pricing</h2>
        <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
      </div>

      <div className="row">

        <div className="col-lg-4" data-aos="fade-up" data-aos-delay="100">
          <div className="box">
            <h3>Free Plan</h3>
            <h4><sup>$</sup>0<span>per month</span></h4>
            <ul>
              <li><i className="bx bx-check"></i> Quam adipiscing vitae proin</li>
              <li><i className="bx bx-check"></i> Nec feugiat nisl pretium</li>
              <li><i className="bx bx-check"></i> Nulla at volutpat diam uteera</li>
              <li className="na"><i className="bx bx-x"></i> <span>Pharetra massa massa ultricies</span></li>
              <li className="na"><i className="bx bx-x"></i> <span>Massa ultricies mi quis hendrerit</span></li>
            </ul>
            <a href="#" className="buy-btn">Get Started</a>
          </div>
        </div>

        <div className="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="200">
          <div className="box featured">
            <h3>Business Plan</h3>
            <h4><sup>$</sup>29<span>per month</span></h4>
            <ul>
              <li><i className="bx bx-check"></i> Quam adipiscing vitae proin</li>
              <li><i className="bx bx-check"></i> Nec feugiat nisl pretium</li>
              <li><i className="bx bx-check"></i> Nulla at volutpat diam uteera</li>
              <li><i className="bx bx-check"></i> Pharetra massa massa ultricies</li>
              <li><i className="bx bx-check"></i> Massa ultricies mi quis hendrerit</li>
            </ul>
            <a href="#" className="buy-btn">Get Started</a>
          </div>
        </div>

        <div className="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="300">
          <div className="box">
            <h3>Developer Plan</h3>
            <h4><sup>$</sup>49<span>per month</span></h4>
            <ul>
              <li><i className="bx bx-check"></i> Quam adipiscing vitae proin</li>
              <li><i className="bx bx-check"></i> Nec feugiat nisl pretium</li>
              <li><i className="bx bx-check"></i> Nulla at volutpat diam uteera</li>
              <li><i className="bx bx-check"></i> Pharetra massa massa ultricies</li>
              <li><i className="bx bx-check"></i> Massa ultricies mi quis hendrerit</li>
            </ul>
            <a href="#" className="buy-btn">Get Started</a>
          </div>
        </div>

      </div>

    </div>
  </section> */}

          {/* <section id="faq" className="faq section-bg">
    <div className="container" data-aos="fade-up">

      <div className="section-title">
        <h2>Frequently Asked Questions</h2>
        <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
      </div>

      <div className="faq-list">
        <ul>
          <li data-aos="fade-up" data-aos-delay="100">
            <i className="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" className="collapse" data-bs-target="#faq-list-1">Non consectetur a erat nam at lectus urna duis? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
            <div id="faq-list-1" className="collapse show" data-bs-parent=".faq-list">
              <p>
                Feugiat pretium nibh ipsum consequat. Tempus iaculis urna id volutpat lacus laoreet non curabitur gravida. Venenatis lectus magna fringilla urna porttitor rhoncus dolor purus non.
              </p>
            </div>
          </li>

          <li data-aos="fade-up" data-aos-delay="200">
            <i className="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-2" className="collapsed">Feugiat scelerisque varius morbi enim nunc? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
            <div id="faq-list-2" className="collapse" data-bs-parent=".faq-list">
              <p>
                Dolor sit amet consectetur adipiscing elit pellentesque habitant morbi. Id interdum velit laoreet id donec ultrices. Fringilla phasellus faucibus scelerisque eleifend donec pretium. Est pellentesque elit ullamcorper dignissim. Mauris ultrices eros in cursus turpis massa tincidunt dui.
              </p>
            </div>
          </li>

          <li data-aos="fade-up" data-aos-delay="300">
            <i className="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-3" className="collapsed">Dolor sit amet consectetur adipiscing elit? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
            <div id="faq-list-3" className="collapse" data-bs-parent=".faq-list">
              <p>
                Eleifend mi in nulla posuere sollicitudin aliquam ultrices sagittis orci. Faucibus pulvinar elementum integer enim. Sem nulla pharetra diam sit amet nisl suscipit. Rutrum tellus pellentesque eu tincidunt. Lectus urna duis convallis convallis tellus. Urna molestie at elementum eu facilisis sed odio morbi quis
              </p>
            </div>
          </li>

          <li data-aos="fade-up" data-aos-delay="400">
            <i className="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-4" className="collapsed">Tempus quam pellentesque nec nam aliquam sem et tortor consequat? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
            <div id="faq-list-4" className="collapse" data-bs-parent=".faq-list">
              <p>
                Molestie a iaculis at erat pellentesque adipiscing commodo. Dignissim suspendisse in est ante in. Nunc vel risus commodo viverra maecenas accumsan. Sit amet nisl suscipit adipiscing bibendum est. Purus gravida quis blandit turpis cursus in.
              </p>
            </div>
          </li>

          <li data-aos="fade-up" data-aos-delay="500">
            <i className="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-5" className="collapsed">Tortor vitae purus faucibus ornare. Varius vel pharetra vel turpis nunc eget lorem dolor? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
            <div id="faq-list-5" className="collapse" data-bs-parent=".faq-list">
              <p>
                Laoreet sit amet cursus sit amet dictum sit amet justo. Mauris vitae ultricies leo integer malesuada nunc vel. Tincidunt eget nullam non nisi est sit amet. Turpis nunc eget lorem dolor sed. Ut venenatis tellus in metus vulputate eu scelerisque.
              </p>
            </div>
          </li>

        </ul>
      </div>

    </div>
  </section> */}

          <section id="contact" className="contact">
            <div className="container" data-aos="fade-up">

              <div className="section-title">
                <h2>Contact</h2>
              </div>

              <div className="row">

                <div className="col-lg-5 d-flex align-items-stretch">
                  <div className="info">
                    <div className="address">
                      <i className="bi bi-geo-alt"></i>
                      <h4>Location:</h4>
                      <p>New Jersey, USA</p>
                    </div>

                    <div className="email">
                      <i className="bi bi-envelope"></i>
                      <h4>Email:</h4>
                      <p>unitechcommunity@gmail.com</p>
                    </div>

                    <div className="phone">
                      <i className="ri-facebook-fill"></i>
                      <p><a href="https://www.facebook.com/profile.php?id=61553463496892">FaceBook</a></p>

                    </div>

                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1563360.035766697!2d-76.04347107141685!3d40.06565067637653!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c0fb959e00409f%3A0x2cd27b07f83f6d8d!2sNew%20Jersey!5e0!3m2!1sen!2sus!4v1700613941026!5m2!1sen!2sus" frameBorder="0" style={{ border: "0", width: "100%", height: "290px" }} allowFullScreen></iframe>

                  </div>

                </div>

                <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
                  <form action="https://formsubmit.co/unitechcommunity@gmail.com" method="POST" role="form" className="php-email-form">
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label htmlFor="name">Your Name</label>
                        <input type="text" name="name" className="form-control" id="name" required />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="name">Your Email</label>
                        <input type="email" className="form-control" name="email" id="email" required />
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="name">Subject</label>
                      <input type="text" className="form-control" name="subject" id="subject" required />
                    </div>
                    <div className="form-group">
                      <label htmlFor="name">Message</label>
                      <textarea className="form-control" name="message" rows="10" required></textarea>
                    </div>
                    <div className="my-3">
                      <div className="loading">Loading</div>
                      <div className="error-message"></div>
                      <div className="sent-message">Your message has been sent. Thank you!</div>
                    </div>
                    <div className="text-center"><button type="submit">Send Message</button></div>
                  </form>
                </div>

              </div>

            </div>
          </section>

        </main>


        <footer id="footer">

          <div className="footer-newsletter">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6">
                  <h4>Join Our Newsletter</h4>
                  <form action="" method="post">
                    <input type="email" name="email" />
                    <input type="submit" value="Subscribe" />
                  </form>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="footer-top">
    <div className="container">
      <div className="row">

        <div className="col-lg-3 col-md-6 footer-contact">
          <h3>Arsha</h3>
          <p>
            A108 Adam Street <br />
            New York, NY 535022<br />
            United States <br /><br />
            <strong>Phone:</strong> +1 5589 55488 55<br />
            <strong>Email:</strong> info@example.com<br />
          </p>
        </div>

        <div className="col-lg-3 col-md-6 footer-links">
          <h4>Useful Links</h4>
          <ul>
            <li><i className="bx bx-chevron-right"></i> <a href="#">Home</a></li>
            <li><i className="bx bx-chevron-right"></i> <a href="#">About us</a></li>
            <li><i className="bx bx-chevron-right"></i> <a href="#">Services</a></li>
            <li><i className="bx bx-chevron-right"></i> <a href="#">Terms of service</a></li>
            <li><i className="bx bx-chevron-right"></i> <a href="#">Privacy policy</a></li>
          </ul>
        </div>

        <div className="col-lg-3 col-md-6 footer-links">
          <h4>Our Services</h4>
          <ul>
            <li><i className="bx bx-chevron-right"></i> <a href="#">Web Design</a></li>
            <li><i className="bx bx-chevron-right"></i> <a href="#">Web Development</a></li>
            <li><i className="bx bx-chevron-right"></i> <a href="#">Product Management</a></li>
            <li><i className="bx bx-chevron-right"></i> <a href="#">Marketing</a></li>
            <li><i className="bx bx-chevron-right"></i> <a href="#">Graphic Design</a></li>
          </ul>
        </div>

        <div className="col-lg-3 col-md-6 footer-links">
          <h4>Our Social Networks</h4>
          <p>Cras fermentum odio eu feugiat lide par naso tierra videa magna derita valies</p>
          <div className="social-links mt-3">
            <a href="#" className="twitter"><i className="bx bxl-twitter"></i></a>
            <a href="#" className="facebook"><i className="bx bxl-facebook"></i></a>
            <a href="#" className="instagram"><i className="bx bxl-instagram"></i></a>
            <a href="#" className="google-plus"><i className="bx bxl-skype"></i></a>
            <a href="#" className="linkedin"><i className="bx bxl-linkedin"></i></a>
          </div>
        </div>

      </div>
    </div>
  </div> */}

          <div className="container footer-bottom clearfix">
            <div className="copyright">
              &copy; Copyright <strong><span>Unitech Community</span></strong>. All Rights Reserved
            </div>
            <div className="credits">
              Designed forby Unitech
            </div>
          </div>
        </footer>

        {/* <div id="preloader"></div> */}
        <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>

      </div>
    </div>
  );
}

export default Home;
