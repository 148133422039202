// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, getDocs, addDoc, setDoc, doc, getDoc, query, where } from 'firebase/firestore/lite';
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { GoogleAuthProvider, signInWithPopup, signOut } from "firebase/auth";
import { GithubAuthProvider } from "firebase/auth";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import 'firebase/auth';
import { async } from "@firebase/util";


const firebaseConfig = {
    apiKey: "AIzaSyAeNP1K1l4Ze8uz9uF4W2EIVbLvZwVzoww",
    authDomain: "unitech-fd90b.firebaseapp.com",
    projectId: "unitech-fd90b",
    storageBucket: "unitech-fd90b.appspot.com",
    messagingSenderId: "605607733028",
    appId: "1:605607733028:web:9509353ab7e962e98575b1",
    measurementId: "G-JVBNRWYTEX",
    databaseURL: "https://unitech-fd90b-default-rtdb.firebaseio.com"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const database = getDatabase(app);
const storage = getStorage(app);
const auth = getAuth();
auth.languageCode = 'en';
// To apply the default browser preference instead of explicitly setting it.
// auth.useDeviceLanguage();
const googleProvider = new GoogleAuthProvider();
const gitHubProvider = new GithubAuthProvider();

gitHubProvider.addScope('repo');

//TODO: Remove github secret 13449fbff3289d0e5a79070245a722b76bdfa42d

const api = {

    addDocument: async (col, data) => {
        try {
            const docRef = await addDoc(collection(db, col), data);
            console.log("Document written with ID: ", docRef.id);
        } catch (e) {
            console.error("Error adding document: ", e);
        }
    },

    getDocument: async (col) => {
        const querySnapshot = await getDocs(collection(db, col));
        querySnapshot.forEach((doc) => {
            console.log(`${doc.id} => ${doc.data()}`);
        });
    },

    uploadFile: async (data, next) => {
        const storageRef = ref(storage, data.fullPath);

        const uploadTask = uploadBytesResumable(storageRef, data.file);
        
        // Register three observers:
        // 1. 'state_changed' observer, called any time the state changes
        // 2. Error observer, called on failure
        // 3. Completion observer, called on successful completion
        uploadTask.on('state_changed', 
          (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
              case 'paused':
                console.log('Upload is paused');
                break;
              case 'running':
                console.log('Upload is running');
                break;
            }
          }, 
          (error) => {
            // Handle unsuccessful uploads
            next({status: false, url: null, error});
          }, 
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              console.log('File available at', downloadURL);
              next({status: true, url: downloadURL, error: null});
            });
          }
        );
    },


    updateUserChallenge: async (data) => {
        try {
            await setDoc(doc(db, "user-challenges", data.id), data, { merge: true });
            return { failed: false, error: null };
        } catch (e) {
            console.error("Error adding document: ", e);
            return { failed: true, error: e };
        }
    },

    updateUserArticle: async (data) => {
        try {
            await setDoc(doc(db, "user-articles", data.id), data, { merge: true });
            return { failed: false, error: null };
        } catch (e) {
            console.error("Error adding document: ", e);
            return { failed: true, error: e };
        }
    },

    updateUserData: async (data) => {
        try {
            await setDoc(doc(db, "users", data.uid), data, { merge: true });
            return { failed: false, error: null };
        } catch (e) {
            console.error("Error adding document: ", e);
            return { failed: true, error: e };
        }
    },

    checkIdAvailability:async (id, col) => {
        const docRef = doc(db, col, id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            return {exist: true};
        } else {
            // docSnap.data() will be undefined in this case
            console.log("No such document!");
            return {exist: false};
        }
    },

    checkCurrentUser: async () => {
        try {
            var user = auth.currentUser;
            
            if (user == null) {
                return { active: false, user: null };
            } else {
                return { active: true, user };
            }
        } catch (error) {
            console.log("error: ", error)
            return { active: false, user: null };
        }
    },

    getUserData: async (uid) => {
        const docRef = doc(db, "users", uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            console.log("Document data:", docSnap.data());
            return {status: true, user: docSnap.data()};
        } else {
            // docSnap.data() will be undefined in this case
            console.log("No such document!");
            return {status: false, user: null};
        }
    },


    getDataById: async (id, col) => {
        const docRef = doc(db, col, id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            console.log("Document data:", docSnap.data());
            return {status: true, data: docSnap.data()};
        } else {
            // docSnap.data() will be undefined in this case
            console.log("No such document!");
            return {status: false, data: null};
        }
    },

    getAllDataFrom: async (col) => { // col can be e.g "users"...
        const q = query(collection(db, col));

        const querySnapshot = await getDocs(q);
        var data = [];
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " => ", doc.data());
            data.push({ ...doc.data(), id: doc.id });
        });
        return data;
    },

    getDataWhere: async (uid, col) => { // col can be e.g "users"...
        const q = query(collection(db, col), where("uid", "==", uid));

        const querySnapshot = await getDocs(q);
        var data = [];
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " => ", doc.data());
            data.push({ ...doc.data(), id: doc.id });
        });
        return data;
    },

    handleGoogleSignIn: async () => {
        return signInWithPopup(auth, googleProvider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                // The signed-in user info.
                const user = result.user;
                return { credential, result, status: true };
                // IdP data available using getAdditionalUserInfo(result)
                // ...
            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                return { status: false, errorCode, errorMessage, email, credential };
                // ...
            });
    },

    handleGithubSignIn: async () => {
        return signInWithPopup(auth, gitHubProvider)
            .then((result) => {
                // This gives you a GitHub Access Token. You can use it to access the GitHub API.
                const credential = GithubAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;

                // The signed-in user info.
                const user = result.user;
                return { credential, result, status: true };
                // IdP data available using getAdditionalUserInfo(result)
                // ...
            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential = GithubAuthProvider.credentialFromError(error);
                // ...
                return { status: false, errorCode, errorMessage, email, credential };
            });
    },

    handleEmailSignIn: async (email, password) => {
        return signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed up 
                const user = userCredential.user;
                return { userCredential, status: true };
                // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                return { status: false, errorCode, errorMessage };
                // ..
            });
    },

    handleEmailSignUp: async (email, password) => {

        return createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed up 
                const user = userCredential.user;
                return { userCredential, status: true };
                // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                return { status: false, errorCode, errorMessage };
                // ..
            });
    },

    handleSignOut: async () => {
        return signOut(auth).then(() => {
            // Sign-out successful.
            return { status: true };
        }).catch((error) => {
            // An error happened.
            return { status: false };
        });
    },

    getCities: async (db) => {
        const citiesCol = collection(db, 'cities');
        const citySnapshot = await getDocs(citiesCol);
        const cityList = citySnapshot.docs.map(doc => doc.data());
        return cityList;
    },



}

export default api;