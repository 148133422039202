import React from "react";
import {
    MDBCard,
    MDBContainer,
    MDBCol,
    MDBIcon,
    MDBRipple,
    MDBRow,
    MDBBtn,
} from "mdb-react-ui-kit";
import firebaseApi from '../utils/firebase';

export default function Challenge(props) {

    const [id, setId] = React.useState("");
    const [data, setData] = React.useState({});

    React.useEffect(() => {
        var idd = window.location.pathname.replace("/challenge/", "");
        // Access the 'id' parameter in your component
        console.log('ID from URL:', idd);
        setId(idd);
        getData(idd);
    }, []);

    const getData = async (i) => {
        firebaseApi.getDataById(i, 'user-challenges').then((res) => {
            if (res.status) {
                setData(res.data)
            }
        })
    }

    return (
        <section style={{ backgroundColor: '#37517e' }}>
            <MDBContainer className="py-5">
                <MDBCard className="px-3 pt-3" >
                    <div>
                        <MDBRipple
                            className="bg-image hover-overlay shadow-1-strong ripple rounded-5 mb-4"
                            rippleTag="div"
                            rippleColor="light" style={{width: '100%'}}
                        >
                            <MDBRow style={{width: '100%'}}>
                                <div style={{
                                    minHeight: '350px',
                                    maxHeight: '350px',
                                    width: '100%',
                                    overflow: 'hidden', // To clip the image if it exceeds the maxHeight
                                    backgroundImage: `url(${data.coverImg})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center', // Center the image within the container
                                }} />

                            </MDBRow>
                            <a href="#!">
                                <div
                                    className="mask"
                                    style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
                                ></div>
                            </a>
                        </MDBRipple>
                        <MDBRow className="mb-3">
                            <MDBCol col="6">
                                <div className="text-info">
                                    {/* <MDBIcon fas icon="plane" className="me-1" /> */}
                                    {/* <MDBIcon fas icon="newspaper" className="me-1" /> */}
                                    <MDBIcon fas icon="chalkboard-teacher" className="me-1" />
                                    Challenge
                                </div>
                                
                                <u> 15.07.2023</u>
                            </MDBCol>
                            <MDBCol col="6" className="text-end">
                                <MDBBtn style={{marginLeft: '15px'}}>Register To This Challenge<MDBIcon far icon="edit" style={{marginLeft: '7px'}} /></MDBBtn>
                            </MDBCol>
                        </MDBRow>
                        <a href="#!" className="text-dark">
                            <h5>{data.title}</h5>
                            <p>
                                {data.description}
                            </p>
                        </a>
                        <hr />
                        <div className="p-4" dangerouslySetInnerHTML={{ __html: data.body }} />
                        <hr />
                        <a href="#!" className="text-dark">
                            <MDBRow className="mb-4 border-bottom pb-2">
                                <MDBCol size="3">
                                    <img
                                        src="https://mdbcdn.b-cdn.net/img/new/standard/city/041.webp"
                                        className="img-fluid shadow-1-strong rounded"
                                        alt="Hollywood Sign on The Hill"
                                    />
                                </MDBCol>

                                <MDBCol size="9">
                                    <p className="mb-2">
                                        <strong>Lorem ipsum dolor sit amet</strong>
                                    </p>
                                    <p>
                                        <u> 15.07.2020</u>
                                    </p>
                                </MDBCol>
                            </MDBRow>
                        </a>
                        <a href="#!" className="text-dark">
                            <MDBRow className="mb-4 border-bottom pb-2">
                                <MDBCol size="3">
                                    <img
                                        src="https://mdbcdn.b-cdn.net/img/new/standard/city/042.webp"
                                        className="img-fluid shadow-1-strong rounded"
                                        alt="Hollywood Sign on The Hill"
                                    />
                                </MDBCol>

                                <MDBCol size="9">
                                    <p className="mb-2">
                                        <strong>Lorem ipsum dolor sit amet</strong>
                                    </p>
                                    <p>
                                        <u> 15.07.2020</u>
                                    </p>
                                </MDBCol>
                            </MDBRow>
                        </a>
                        <a href="#!" className="text-dark">
                            <MDBRow className="mb-4 border-bottom pb-2">
                                <MDBCol size="3">
                                    <img
                                        src="https://mdbcdn.b-cdn.net/img/new/standard/city/043.webp"
                                        className="img-fluid shadow-1-strong rounded"
                                        alt="Hollywood Sign on The Hill"
                                    />
                                </MDBCol>

                                <MDBCol size="9">
                                    <p className="mb-2">
                                        <strong>Lorem ipsum dolor sit amet</strong>
                                    </p>
                                    <p>
                                        <u> 15.07.2020</u>
                                    </p>
                                </MDBCol>
                            </MDBRow>
                        </a>
                        <a href="#!" className="text-dark">
                            <MDBRow className="mb-4 border-bottom pb-2">
                                <MDBCol size="3">
                                    <img
                                        src="https://mdbcdn.b-cdn.net/img/new/standard/city/044.webp"
                                        className="img-fluid shadow-1-strong rounded"
                                        alt="Hollywood Sign on The Hill"
                                    />
                                </MDBCol>

                                <MDBCol size="9">
                                    <p className="mb-2">
                                        <strong>Lorem ipsum dolor sit amet</strong>
                                    </p>
                                    <p>
                                        <u> 15.07.2020</u>
                                    </p>
                                </MDBCol>
                            </MDBRow>
                        </a>
                    </div>
                </MDBCard>
            </MDBContainer>
        </section>
    );
}