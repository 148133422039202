import React from 'react';
import './App.css';
import Home from './pages/home';
import Authentication from './components/authentication';
import { Routes, Route, Outlet, Link } from "react-router-dom";
import firebaseApi from './utils/firebase'
import { useCookies } from 'react-cookie';
import ProfilePage from './pages/profile';
import Challenge from './pages/challenge';
import Article from './pages/article';
import { async } from '@firebase/util';
import {
    MDBIcon
} from 'mdb-react-ui-kit';

function App() {

  const [user, setUser] = React.useState(null);
  const [runScript, setRunScript] = React.useState(true);
  const [cookies, setCookie, removeCookie] = useCookies(['u_u']);


  const handleInitScripts = () => {
    const select = (el, all = false) => {
      console.log("el: ", el);
      if (el) {
        el = el.trim()
        if (all) {
          return [...document.querySelectorAll(el)]
        } else {
          return document.querySelector(el)
        }

      }
    }

    /**
     * Easy event listener function
     */
    const on = (type, el, listener, all = false) => {
      let selectEl = select(el, all)
      if (selectEl) {
        if (all) {
          selectEl.forEach(e => e.addEventListener(type, listener))
        } else {
          selectEl.addEventListener(type, listener)
        }
      }
    }

    /**
     * Easy on scroll event listener 
     */
    const onscroll = (el, listener) => {
      el.addEventListener('scroll', listener)
    }

    /**
     * Navbar links active state on scroll
     */
    let navbarlinks = select('#navbar .scrollto', true)
    const navbarlinksActive = () => {
      let position = window.scrollY + 200
      navbarlinks.forEach(navbarlink => {
        if (!navbarlink.hash) return
        let section = select(navbarlink.hash)
        if (!section) return
        if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
          navbarlink.classList.add('active')
        } else {
          navbarlink.classList.remove('active')
        }
      })
    }
    window.addEventListener('load', navbarlinksActive)
    onscroll(document, navbarlinksActive)

    /**
     * Scrolls to an element with header offset
     */
    const scrollto = (el) => {
      let header = select('#header')
      let offset = header.offsetHeight

      let elementPos = select(el).offsetTop
      window.scrollTo({
        top: elementPos - offset,
        behavior: 'smooth'
      })
    }

    /**
     * Toggle .header-scrolled class to #header when page is scrolled
     */
    let selectHeader = select('#header')
    if (selectHeader) {
      const headerScrolled = () => {
        if (window.scrollY > 100) {
          selectHeader.classList.add('header-scrolled')
        } else {
          selectHeader.classList.remove('header-scrolled')
        }
      }
      window.addEventListener('load', headerScrolled)
      onscroll(document, headerScrolled)
    }

    /**
     * Back to top button
     */
    let backtotop = select('.back-to-top')
    if (backtotop) {
      const toggleBacktotop = () => {
        if (window.scrollY > 100) {
          backtotop.classList.add('active')
        } else {
          backtotop.classList.remove('active')
        }
      }
      window.addEventListener('load', toggleBacktotop)
      onscroll(document, toggleBacktotop)
    }

    /**
     * Mobile nav toggle
     */
    on('click', '.mobile-nav-toggle', function (e) {
      console.log("clicked:");
      select('#navbar').classList.toggle('navbar-mobile')
      this.classList.toggle('bi-list')
      this.classList.toggle('bi-x')
    })

    /**
     * Mobile nav dropdowns activate
     */
    on('click', '.navbar .dropdown > a', function (e) {
      if (select('#navbar').classList.contains('navbar-mobile')) {
        e.preventDefault()
        this.nextElementSibling.classList.toggle('dropdown-active')
      }
    }, true)

    /**
     * Scrool with ofset on links with a class name .scrollto
     */
    on('click', '.scrollto', function (e) {
      if (select(this.hash)) {
        e.preventDefault()

        let navbar = select('#navbar')
        if (navbar.classList.contains('navbar-mobile')) {
          navbar.classList.remove('navbar-mobile')
          let navbarToggle = select('.mobile-nav-toggle')
          navbarToggle.classList.toggle('bi-list')
          navbarToggle.classList.toggle('bi-x')
        }
        scrollto(this.hash)
      }
    }, true)

    /**
     * Scroll with ofset on page load with hash links in the url
     */
    window.addEventListener('load', () => {
      if (window.location.hash) {
        if (select(window.location.hash)) {
          scrollto(window.location.hash)
        }
      }
    });

    /**
     * Preloader
     */
    // let preloader = select('#preloader');

    // preloader.remove();

    /**
     * Initiate  glightbox 
     */
    const glightbox = window.GLightbox({
      selector: '.glightbox'
    });

    /**
     * Skills animation
     */
    let skilsContent = select('.skills-content');
    if (skilsContent) {
      new window.Waypoint({
        element: skilsContent,
        offset: '80%',
        handler: function (direction) {
          let progress = select('.progress .progress-bar', true);
          progress.forEach((el) => {
            el.style.width = el.getAttribute('aria-valuenow') + '%'
          });
        }
      })
    }

    /**
     * Porfolio isotope and filter
     */
    window.addEventListener('load', () => {
      let portfolioContainer = select('.portfolio-container');
      if (portfolioContainer) {
        let portfolioIsotope = new window.Isotope(portfolioContainer, {
          itemSelector: '.portfolio-item'
        });

        let portfolioFilters = select('#portfolio-flters li', true);

        on('click', '#portfolio-flters li', function (e) {
          e.preventDefault();
          portfolioFilters.forEach(function (el) {
            el.classList.remove('filter-active');
          });
          this.classList.add('filter-active');

          portfolioIsotope.arrange({
            filter: this.getAttribute('data-filter')
          });
          portfolioIsotope.on('arrangeComplete', function () {
            window.AOS.refresh()
          });
        }, true);
      }

    });

    /**
     * Initiate portfolio lightbox 
     */
    const portfolioLightbox = window.GLightbox({
      selector: '.portfolio-lightbox'
    });

    /**
     * Portfolio details slider
     */
    new window.Swiper('.portfolio-details-slider', {
      speed: 400,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      }
    });

    /**
     * Animation on scroll
     */
    window.addEventListener('load', () => {
      window.AOS.init({
        duration: 1000,
        easing: "ease-in-out",
        once: true,
        mirror: false
      });
    });

  }

  React.useEffect(() => {
    if (runScript) {
      handleInitScripts();
      setRunScript(false);
    }
    if (!user) {
      checkUserSession();
    }
  }, []);

  const checkUserSession = async () => {
    await firebaseApi.checkCurrentUser().then(async (res) => {
      if (res.active) {
        var user = res.user;

        await firebaseApi.getUserData(user.uid).then((uRes) => {
          if (uRes.status) {
            setUser(uRes.user);
            setCookie("u_u", JSON.stringify(uRes.user));
          }
        })
      }
    })
  }


  return (
    <div>
      <Nabar user={user} setUser={setUser} />
      {!user && (<Authentication user={user} setUser={setUser} />)}

      <Routes>
        <Route path="/">
          <Route path="/" element={<Home user={user} setUser={setUser} />} />
          <Route path="about" element={<Home user={user} setUser={setUser} section="about" />} />
          <Route path="services" element={<Home user={user} setUser={setUser} section="services" />} />
          <Route path="portfolio" element={<Home user={user} setUser={setUser} section="portfolio" />} />
          <Route path="team" element={<Home user={user} setUser={setUser} section="team" />} />
          <Route path="contact" element={<Home user={user} setUser={setUser} section="contact" />} />
          <Route path="profile" element={<ProfilePage user={user} setUser={setUser} section="contact" />} />
          <Route path="challenge/:id" element={<Challenge user={user} setUser={setUser} section="contact" />} />
          <Route path="article/:id" element={<Article user={user} setUser={setUser} section="contact" />} />
          
          {/* <Route path="dashboard" element={<Dashboard />} /> */}

          <Route path="*" element={<Home user={user} setUser={setUser} section={false} />} />
        </Route>
      </Routes>
    </div>
  );
}


function Nabar(props) {

  const [showAuth, setShowAuth] = React.useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['u_u']);

  const toggleOpen = () => setShowAuth(!showAuth);

  return (
    <>
      <header id="header" className="fixed-top ">
        <div className="container d-flex align-items-center">

          <h1 className="logo me-auto"><Link to="/" style={{display: 'flex', alignItems: 'center'}}>
            <img src="assets/img/image (3).png" style={{maxHeight: '60px', maxWidth: '60px'}}/>
            
            </Link></h1>

          <nav id="navbar" className="navbar">
            <ul>
              <li><Link className="nav-link scrollto active" to="/">Home</Link></li>
              <li><Link className="nav-link scrollto" to="/about">About</Link></li>
              <li><Link className="nav-link scrollto" to="/services">Activities</Link></li>
              <li><Link className="nav-link scrollto" to="/portfolio">Latest Projects</Link></li>
              <li><Link className="nav-link scrollto" to="/team">Team</Link></li>
              <li><Link className="nav-link scrollto" to="/contact">Contact</Link></li>
              {props.user ? (
              <li className="dropdown" style={{paddingLeft: '30px'}}>
                <div className="getstarted" style={{color: 'white', display: 'flex', flexDirection: 'row', alignContent: 'baseline', cursor: 'pointer'}}>
                  <span>Dashboard</span>
                  <MDBIcon far icon="user-circle" style={{margin: '5px'}}/>
                </div>
                <ul>
                  <li style={{color: 'white', display: 'flex', flexDirection: 'row', cursor: 'pointer'}}>
                    <Link to="/new-post"><MDBIcon fas icon="plus-circle" style={{margin: '5px'}}/>Add Post</Link>
                  </li>
                  {/* <li className="dropdown"><Link to="/"><span>Deep Drop Down</span> <i className="bi bi-chevron-right"></i></Link>
                    <ul>
                      <li><Link to="/">Deep Drop Down 1</Link></li>
                      <li><Link to="/">Deep Drop Down 2</Link></li>
                      <li><Link to="/">Deep Drop Down 3</Link></li>
                      <li><Link to="/">Deep Drop Down 4</Link></li>
                      <li><Link to="/">Deep Drop Down 5</Link></li>
                    </ul>
                  </li> */}
                  <li style={{color: 'white', display: 'flex', flexDirection: 'row', cursor: 'pointer'}}>
                    <Link to="/profile">
                      <MDBIcon fas icon="user" style={{margin: '5px'}}/>
                      Edit Profile
                    </Link>
                  </li>
                  <li onClick={() => { props.setUser(null); removeCookie("u_u") }} style={{fontSize: '15px', padding: '10px 0 10px 25px', color: 'black', display: 'flex', flexDirection: 'row', cursor: 'pointer'}}>
                      <MDBIcon fas icon="sign-out-alt" style={{margin: '5px 5px 0 0'}}/>
                      Logout
                  </li>
                  {/* <li><Link to="/">Drop Down 4</Link></li> */}
                </ul>
              </li>
              ) : (
                <li><Link className="getstarted scrollto"
                  onClick={toggleOpen}>Get Started</Link></li>
              )}

            </ul>
            <i className="bi bi-list mobile-nav-toggle"></i>
          </nav>
          <hr />

          {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined above. */}
          <Outlet />

        </div>
      </header >
      {showAuth && !props.user && (
        <Authentication toggleOpen={toggleOpen} setShowAuth={setShowAuth} showAuth={showAuth} {...props} />
      )
      }
    </>
  );
}

export default App;
